import { Component, Inject, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from './shared/services/navigation.service';
import { LoaderComponent } from './components/loader/loader.component';
import { Meta } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ServiceWorkerService } from './services/service-worker.service';
import { CrispChatService } from './shared/services/crisp-chat.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [LoaderComponent, RouterModule, ServiceWorkerModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private crispChatService: CrispChatService,
    private navigation: NavigationService,
    angulartics2: Angulartics2GoogleGlobalSiteTag,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private meta: Meta,
    @Inject('WEBSITE_ROOT_URL') private WEBSITE_ROOT_URL: string,
    _sws: ServiceWorkerService
  ) {
    angulartics2.startTracking();
    translate.setDefaultLang('sr');
    translate.use('sr');
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.crispChatService.refresh(event, this.route);
        this.navigation.addToHistory(event.url);

        let route = this.route;
        while (route.firstChild) {
          route = route.firstChild;
        }

        const title = route.snapshot.title || 'Coolivery';
        this.meta.updateTag({
          property: 'og:title',
          content: title,
        });

        const description =
          route.snapshot.data['description'] ||
          'Coolivery komplet je tvoj lični pomoćnik u kuhinji! Svaki naš komplet sadrži sve što ti je potrebno za pripremu ukusnih obroka.';

        this.meta.updateTag({
          name: 'description',
          content: description,
        });

        this.meta.updateTag({
          property: 'og:description',
          content: description,
        });

        this.meta.updateTag({
          property: 'og:type',
          content: 'website',
        });

        this.meta.updateTag({
          property: 'og:url',
          content: this.WEBSITE_ROOT_URL + event.url,
        });

        this.meta.updateTag({
          property: 'og:image',
          content:
            'https://res.cloudinary.com/dd3qzmlsr/image/upload/f_jpg,q_auto/v1/website/images/social/social-share.jpg',
        });
      }
    });
  }
}
